<template>
<div class="dashboard-page">
  <div class="row">
    <div class="col-sm-12">
      <vuestic-widget :loading="loading" class="tiles-widget">
        <div class="row">
          <div class="col-sm-3">
            <div class="tile-box-text-sent text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-commenting-o"></i>
              </div>
              <div class="tile-header">Texts Sent</div>
              <div class="tile-body">
                {{analytics.textSent}}<sub> / day</sub>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="tile-box-text-received text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-comments"></i>
              </div>
              <div class="tile-header">Texts Received</div>
              <div class="tile-body">
                {{analytics.textReceived}}<sub> / day</sub>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="tile-box-text-failed text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-ban"></i>
              </div>
              <div class="tile-header">Texts Failed</div>
              <div class="tile-body">
                {{analytics.textFailed}} <sub> / {{analytics.textSent}}</sub> <strong>({{analytics.textFailedPercent}}<sup>%</sup>)</strong>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="tile-box-broadcast-sent text-left">
              <div class="tile-background-overlay">
                <i class="fa fa-bullhorn"></i>
              </div>
              <div class="tile-header">Broadcasts Sent</div>
              <div class="tile-body">
                {{analytics.broadcastSent}}
              </div>
            </div>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <vuestic-widget :loading="loading" class="chart-widget" headerText="Messages">
        <apexchart width="100%" height="400" type="line" :options="chart.messages.options" :series="chart.messages.series"></apexchart>
      </vuestic-widget>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-9">
      <vuestic-widget :loading="loading" class="chart-widget" headerText="Time of Day Statistics">
        <apexchart width="100%" height="400" type="bar" :options="chart.timeOfDay.options" :series="chart.timeOfDay.series"></apexchart>
      </vuestic-widget>
    </div>

    <div class="col-sm-3 widget-flex-box">
      <vuestic-widget :loading="loading" class="list-widget widget-flex-item" headerText="Message Status">
        <div class="analytics-list">
          <div v-for="(item, index) in analytics.list.messageStatus"
            v-bind:key="index"
            class="analytics-list-element row"
          >
            <div class="col-sm-6 text-left">{{ item.title }}</div>
            <div class="col-sm-6 text-right font-weight-bold" :class="`text-${item.color}`">{{ item.value }}</div>
          </div>
        </div>
      </vuestic-widget>
    </div>
  </div>
</div>

</template>

<script>
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export default {
  components: {
  },
  props: {
    filter: {
      type: Object,
      default: {},
    }
  },
  data: function () {
    return {
      loading: false,
      chart : {
        messages: {
          options: {
            chart: {
              id: 'chart-messages',
              toolbar: {
                show: false,
              },
            },
            stroke: {
              curve: 'straight',
              width: 2,
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            markers: {
              size: 4,
            },
            colors: ['#3178c6', '#fe7058'],
            xaxis: {
              categories: []
            },
          },
          series: [
            {
              name: 'Text Sent',
              data: []
            },
            {
              name: 'Text Failed',
              data: []
            },
          ]
        },
        timeOfDay: {
          options: {
            chart: {
              id: 'chart-time-of-day',
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
              }
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
            },
            dataLabels: {
              enabled: false
            },
            colors: ['#3178c6', '#fe7058'],
            xaxis: {
              categories: ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12']
            },
          },
          series: [
            {
              name: 'Text Sent',
              data: [0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              name: 'Text Failed',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
          ]
        }
      },

      analyticsRawData: {},
      analytics: {
        textSent: 0,
        textReceived: 0,
        textFailed: 0,
        broadcastSent: 0,
        textFailedPercent: 0,
        list: {
          messageStatus: {
            sent: {title: 'Text Sent', value: 0, color: 'primary'},
            received: {title: 'Text Received', value: 0, color: 'primary'},
            failed: {title: 'Text Failed', value: 0, color: 'danger'},
          }
        },
      },
    }
  },
  mounted() {
    this.handleFilterUpdate();
  },

  methods: {

    handleFilterUpdate() {
      const filter = {
        business: this.filter.business.value.id,
        rangeDateStart: moment(this.filter.date.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        rangeDateEnd: moment(this.filter.date.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.loading = true
      const param = {
        type: 'sms',
        filter
      }
      this.$store.dispatch('agency/fetchAnalytic', param)
        .then((data) => {
          this.loading = false
          if(!data.success) return;
          this.analyticsRawData = data.data;

          this.updateAverageInfo();
          this.updateChartMessages();
          this.updateChartTimeOfDay();
          this.updateListMessageStatus();
        }).catch(e => {
          this.loading = false
        })
    },

    getHMS(time) {
      const hours = Math.floor(time / (60 * 60));

      const divisorForMinutes = time % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);

      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.ceil(divisorForSeconds);

      return {
        hour: hours,
        min: (minutes < 10) ? '0' + minutes : minutes,
        sec: (seconds < 10) ? '0' + seconds : seconds
      };
    },

    updateAverageInfo() {
      this.analytics.textSent = parseFloat(this.analyticsRawData.textSent);
      this.analytics.textReceived = parseFloat(this.analyticsRawData.textReceived);
      this.analytics.textFailed = parseFloat(this.analyticsRawData.textFailed);
      this.analytics.broadcastSent = parseInt(this.analyticsRawData.broadcastSent);

      this.analytics.textFailedPercent = (this.analytics.textSent) ? parseInt((this.analytics.textFailed / this.analytics.textSent) * 100) : 0;
    },

    updateChartMessages() {
      const xaxisCategories = [];
      const yaxisTextSentCount = [];
      const yaxisTestFailedCount = [];

      const rangeDateStart = moment(this.filter.date.dateRange.startDate).startOf('day');
      const rangeDateEnd = moment(this.filter.date.dateRange.endDate).endOf('day');
      const range = moment.range(rangeDateStart, rangeDateEnd);

      for (let date of range.by('days')) {
        xaxisCategories.push(date.format('MMMM D'));
        let dateKey = date.format('YYYY-MM-DD');
        yaxisTextSentCount.push((dateKey in this.analyticsRawData.textByDate) ? this.analyticsRawData.textByDate[dateKey]['total'] : 0);
        yaxisTestFailedCount.push((dateKey in this.analyticsRawData.textByDate) ? this.analyticsRawData.textByDate[dateKey]['failed'] : 0);
      }

      this.chart.messages.options = {...this.chart.messages.options, ...{
        xaxis: { categories: xaxisCategories }
      }};
      this.chart.messages.series[0].data = yaxisTextSentCount;
      this.chart.messages.series[1].data = yaxisTestFailedCount;
    },

    updateChartTimeOfDay() {
      const xaxisCategories = [];
      const yaxisTextSentCount = [];
      const yaxisTextFailedCount = [];

      const rangeDateStart = moment().startOf('day');
      const rangeDateEnd = moment().endOf('day');
      const range = moment.range(rangeDateStart, rangeDateEnd);

      for (let hour of range.by('hours', { step: 2 })) {
        let nextHour = hour.clone().add(1, 'hours');
        xaxisCategories.push(hour.format('HH') +'-'+ (nextHour.format('HH') !== '00' ? nextHour.format('HH') : 24));

        let hourKey = hour.clone().utc().format('H');
        let hourTextSentCount = (hourKey in this.analyticsRawData.textByHour) ? this.analyticsRawData.textByHour[hourKey]['total'] : 0;
        let hourTextFailedCount = (hourKey in this.analyticsRawData.textByHour) ? this.analyticsRawData.textByHour[hourKey]['failed'] : 0;

        let nextHourKey = nextHour.clone().utc().format('H');
        let nextHourTextSentCount = (nextHourKey in this.analyticsRawData.textByHour) ? this.analyticsRawData.textByHour[nextHourKey]['total'] : 0;
        let nextHourTextFailedCount = (nextHourKey in this.analyticsRawData.textByHour) ? this.analyticsRawData.textByHour[nextHourKey]['failed'] : 0;

        yaxisTextSentCount.push(hourTextSentCount + nextHourTextSentCount);
        yaxisTextFailedCount.push(hourTextFailedCount + nextHourTextFailedCount);
      }

      this.chart.timeOfDay.options = {...this.chart.messages.options, ...{
        xaxis: { categories: xaxisCategories }
      }};
      this.chart.timeOfDay.series[0].data = yaxisTextSentCount;
      this.chart.timeOfDay.series[1].data = yaxisTextFailedCount;
    },

    updateListMessageStatus() {
      this.analytics.list.messageStatus.sent.value = this.analyticsRawData.totalTextSent;
      this.analytics.list.messageStatus.received.value = this.analyticsRawData.totalTextReceived;
      this.analytics.list.messageStatus.failed.value = this.analyticsRawData.totalTextFailed;
    }
  },
};
</script>

<style lang="scss" scoped>
  .widget {
    &.chart-widget {
      .widget-body {
        height: 550px;
      }
    }

    .widget-header {
      color: #a6a6a6;
      border-bottom: 1px solid #a6a6a6;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .widget-flex-box {
    display: flex;
    .widget-flex-item {
      flex: auto;
    }
  }

  .analytics-list {
    .analytics-list-element {
      line-height: 45px;
    }
    .analytics-list-element:nth-child(even){
      background-color: #f2f2f2;
    }
  }

  sub, sup {
    font-size: 60%;
  }

  .tiles-widget {
    [class*="tile-box-"] {
      position: relative;
      color: white;
      background-color: #bdbeb9;
      height: 100%;
      padding: 24px 19px 19px 26px;

      .tile-background-overlay {
        pointer-events: none;
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;

        text-align: right;

        i {
          position: absolute;
          top: calc(50% - 0.5em);
          right: 20px;
          font-size: 80px;
          line-height: 80px;
          color: rgba(255, 253, 253, 0.3);
        }
      }

      .tile-header {
        font-size: 15px;
        font-weight: bold;
      }

      .tile-body {
        margin: 20px 0 0 0;
        font-size: 35px;
        font-weight: bold;
        line-height: 35px;

        sub {
          font-weight: bold;
          font-size: 0.45em;
          bottom: 0em;
        }
        strong {
          font-size: 1.4em;
          sup {
            font-weight: bold;
            top: -1.2em;
            font-size: .35em;
          }
        }
      }
    }

    .tile-box-text-sent {
      background-color: #3178c6;
    }
    .tile-box-text-received{
      background-color: #0d9d58;
    }
    .tile-box-text-failed {
      background-color: #fe7058;
    }
    .tile-box-broadcast-sent{
      background-color: #314a5e;
    }
  }

  @media (max-width: 1550px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 60px;
            line-height: 60px;
          }
        }

        .tile-header {
          font-size: 12px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 25px;
          line-height: 25px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 60px;
            line-height: 60px;
          }
        }

        .tile-header {
          font-size: 11px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: 1210px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 50px;
            line-height: 50px;
          }
        }

        .tile-header {
          font-size: 10px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width: 1110px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 40px;
            line-height: 40px;
          }
        }

        .tile-header {
          font-size: 9px;
          font-weight: normal;
        }

        .tile-body {
          margin: 10px 0 0 0;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .tiles-widget {
      [class*="tile-box-"] {
        padding: 14px 9px 9px 16px;

        .tile-background-overlay {
          i {
            font-size: 35px;
            line-height: 35px;
          }
        }

        .tile-header {
          font-size: 8px;
          font-weight: normal;
        }

        .tile-body {
          margin: 8px 0 0 0;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }


  .transform-rotate-m45 {
    transform: rotate(-45deg);
  }

  .filter-widget{
    .filter-title {
      font-size: 16px;
      font-weight: bold;
      padding-top: 12px;
    }

    .form-group {
      min-height: auto;
    }
  }

  .field-label {
    display: block;
    text-align: left;
    clear: both;
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -0.6rem;
    left: 0;
    margin-bottom: 3px;
  }

  .bus_inst_av{
    font-size: 1rem;
    color: #777;
  }

</style>

